
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

function ProjectsPage({ location, data }) {
  
  const projects = data.allProjectsYaml.nodes

  return (
    <Layout location={location}>
      <Seo title="Projects" />

      { /* TODO: add book info: https://developers.google.com/search/docs/advanced/structured-data/book */}

      <ol className="relative pb-64 border-l border-gray-200 ">
        {projects.map((project, projectIndex) => {
          return (
            <li key={projectIndex} className="mb-10 ml-6">
              <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-200 rounded-full -left-3 ring-8 ring-white ">
                <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 ">{project.title} </h3>
            </li>
          )
        })}
      </ol>

    </Layout>
  )
}

export default ProjectsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allProjectsYaml {
      nodes {
        id
        link
        title
      }
    }
  }
`
